import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogConfirmCancelComponent } from '../confirm-cancel/confirm-cancel.component';
import { DialogAction, DialogConfig } from '../confirm-cancel/confirm-cancel.interface';

@Injectable({ providedIn: 'root' })
export class LicensingDialogService {
  constructor(private dialog: MatDialog) {}

  showCheckUnsavedDialog(): Observable<DialogAction> {
    const dialog: DialogConfig = {
      title: 'You have unsaved changes on this page',
      icon: 'report_problem',
      content: 'If you leave this page, all unsaved changes will be lost',
      actions: [
        {
          label: 'CONTINUE EDITING',
          type: 'primary',
          resultType: 'EDIT',
          resultAction: false,
        },
        {
          label: 'DISCARD CHANGES',
          type: 'secondary',
          resultType: 'DISCARD_CHANGE',
          resultAction: true,
        },
      ],
    };
    return this.dialog.open(DialogConfirmCancelComponent, { maxWidth: '600px', data: dialog }).afterClosed();
  }

  showConfirmDeleteDialog(
    title: string = 'Do you want to delete the selected record?',
    content: string = 'Once deleted, the selected record cannot be recovered'
  ): Observable<DialogAction> {
    const dialog: DialogConfig = {
      title: title,
      icon: 'report_problem',
      content: content,
      actions: [
        {
          label: 'DELETE',
          type: 'primary',
          resultType: 'DELETE',
          resultAction: true,
        },
        {
          label: 'CANCEL',
          type: 'secondary',
          resultType: 'CANCEL',
          resultAction: false,
        },
      ],
    };
    return this.dialog.open(DialogConfirmCancelComponent, { maxWidth: '600px', data: dialog }).afterClosed();
  }

  showConfirmUndoDialog(): Observable<DialogAction> {
    const dialog: DialogConfig = {
      title: 'Do you want to undo the selected record?',
      icon: 'report_problem',
      content: 'Once undid, the selected record cannot be recovered',
      actions: [
        {
          label: 'UNDO',
          type: 'primary',
          resultType: 'UNDO',
          resultAction: true,
        },
        {
          label: 'CANCEL',
          type: 'secondary',
          resultType: 'CANCEL',
          resultAction: false,
        },
      ],
    };
    return this.dialog.open(DialogConfirmCancelComponent, { maxWidth: '600px', data: dialog }).afterClosed();
  }

  updateCancelDialog(): Observable<DialogAction> {
    const dialog: DialogConfig = {
      title: 'Do you want to update the selected record?',
      icon: 'report_problem',
      content: 'Once updated, the old record cannot be recovered',
      actions: [
        {
          label: 'UPDATE',
          type: 'primary',
          resultType: 'UPDATE',
          resultAction: true,
        },
        {
          label: 'CANCEL',
          type: 'secondary',
          resultType: 'CANCEL',
          resultAction: false,
        },
      ],
    };
    return this.dialog.open(DialogConfirmCancelComponent, { maxWidth: '600px', data: dialog }).afterClosed();
  }

  showConfirmMassUpdateDialog(
    title: string = 'Do you want to update the selected records?',
    content: string = 'Once updated, the old records cannot be recovered'
  ): Observable<boolean> {
    const dialog: DialogConfig = {
      title: title,
      icon: 'report_problem',
      content: content,
      actions: [
        {
          label: 'UPDATE',
          type: 'primary',
          resultType: 'UPDATE',
          resultAction: true,
        },
        {
          label: 'CANCEL',
          type: 'secondary',
          resultType: 'CANCEL',
          resultAction: false,
        },
      ],
    };
    const subject = new Subject<boolean>();
    const dialogRef = this.dialog.open(DialogConfirmCancelComponent, { maxWidth: '600px', data: dialog });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        subject.next(result.resultAction);
        subject.complete();
      });

    return subject.asObservable();
  }

  showDeleteCommentDialog(): Observable<boolean> {
    const dialog: DialogConfig = {
      title: 'Do you want to delete this comment?',
      icon: 'report_problem',
      content: 'Once deleted, it cannot be recovered',
      actions: [
        {
          label: 'DELETE',
          type: 'primary',
          resultType: 'DELETE',
          resultAction: true,
        },
        {
          label: 'CANCEL',
          type: 'secondary',
          resultType: 'CANCEL',
          resultAction: false,
        },
      ],
    };
    const subject = new Subject<boolean>();
    const dialogRef = this.dialog.open(DialogConfirmCancelComponent, { maxWidth: '600px', data: dialog });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        subject.next(result.resultAction);
        subject.complete();
      });

    return subject.asObservable();
  }

  showUpdateDialog(): Observable<boolean> {
    const dialog: DialogConfig = {
      title: 'Update Domicile State and Weight Group Values?',
      icon: 'report_problem',
      content: 'The Domicile State and Weight Group for this equipment will change. Please choose from the suggestions',
      actions: [
        {
          label: 'CONFIRM',
          type: 'primary',
          resultType: 'UPDATE',
          resultAction: true,
        },
      ],
    };
    const subject = new Subject<boolean>();
    const dialogRef = this.dialog.open(DialogConfirmCancelComponent, { maxWidth: '600px', data: dialog });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        subject.next(result?.resultAction);
        subject.complete();
      });

    return subject.asObservable();
  }

  showUpdateDomStateAndWeightGroupDialog(messageText: string): Observable<boolean> {
    const dialog: DialogConfig = {
      title: 'Update Domicile State and Weight Group Values.',
      icon: 'report_problem',
      content: messageText,
      actions: [
        {
          label: 'OKAY',
          type: 'primary',
          resultType: 'UPDATE',
          resultAction: true,
        },
      ],
    };
    const subject = new Subject<boolean>();
    const dialogRef = this.dialog.open(DialogConfirmCancelComponent, {
      maxWidth: '600px',
      data: dialog,
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        subject.next(result?.resultAction);
        subject.complete();
      });

    return subject.asObservable();
  }

  showConfirmMassDeleteDialog(
    title: string = 'Do you want to delete the selected records?',
    content: string = 'Once deleted, the selected records cannot be recovered. Any License Codes linked to a Equipment Record will not be deleted'
  ): Observable<DialogAction> {
    const dialog: DialogConfig = {
      title: title,
      icon: 'report_problem',
      content: content,
      actions: [
        {
          label: 'DELETE',
          type: 'primary',
          resultType: 'DELETE',
          resultAction: true,
        },
        {
          label: 'CANCEL',
          type: 'secondary',
          resultType: 'CANCEL',
          resultAction: false,
        },
      ],
    };
    return this.dialog.open(DialogConfirmCancelComponent, { maxWidth: '600px', data: dialog }).afterClosed();
  }
}
