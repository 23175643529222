import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationService, SectionTab } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-section-nav-bar',
  templateUrl: './section-nav-bar.component.html',
  styleUrls: ['./section-nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SectionNavBarComponent implements OnInit {
  @Input() tabs: SectionTab[];
  @Input() activeTab: SectionTab;
  @Output() tabClicked = new EventEmitter<string>();

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {}

  onClickTab(tab: SectionTab) {
    this.tabClicked.emit(tab.label);
    this.navigationService.navigateToSectionTab(tab);
  }
}
