import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { HeaderAction } from '../../../equipment-licensing/components/equipment-records/services/equipment-records-navigation.service';
import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-go-back-header',
  templateUrl: './go-back-header.component.html',
  styleUrls: ['./go-back-header.component.scss'],
})
export class GoBackHeaderComponent implements OnInit {
  @Input() hideBackBtn: boolean = false;
  @Input() label: string;
  @Input() value: string;
  @Input() actions: HeaderAction[];

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {}

  onGoBackClicked() {
    this.navigationService.goBack();
  }
}
