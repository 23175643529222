import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationService, SectionTab } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavBarComponent implements OnInit {
  @Input() tabs: SectionTab[];
  @Input() activeTab: SectionTab;
  @Output() tabClicked = new EventEmitter<string>();
  screenWidth = window.innerWidth;
  isSmallWindow: boolean = false;
  isCollapsed: boolean = false;
  constructor(private navigationService: NavigationService) {
    this.onResize();
  }

  ngOnInit() {
    this.updateScreenSize();
    this.navigationService.collapse$.subscribe((isCollapsed) => (this.isCollapsed = isCollapsed));
  }

  onClickTab(tab: SectionTab) {
    this.tabClicked.emit(tab.label);
    this.navigationService.navigateToSectionTab(tab);
  }

  toggleCollapse() {
    this.navigationService.toggleCollapse();
  }

  updateScreenSize() {
    if (this.screenWidth < 1440) {
      this.isSmallWindow = true;
    } else {
      this.isSmallWindow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    this.updateScreenSize();
  }
}
