import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { takeUntil } from 'rxjs/operators';
import { UserRole } from '../shared/enums/user-role/user-role.enum';
import { UserRoleService } from '../shared/services/user-role/user-role.service';

@Directive({
  selector: '[hideElementIfNotAuthorised]',
})
export class HideElementDirective implements AfterViewInit, OnDestroy {
  @Input() visibleForRole: UserRole = UserRole.ReadOnlyUser;
  private unsubscriber = new Unsubscriber();

  constructor(private elementRef: ElementRef, private userRoleService: UserRoleService) {}

  ngAfterViewInit(): void {
    this.userRoleService
      .getUserRole()
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((loggedInUserRole: UserRole) => {
        const isUserAllowed = this.isUserAllowed(loggedInUserRole);
        this.elementRef.nativeElement.style.display = !isUserAllowed ? 'none' : '';
      });
  }

  private isUserAllowed(loggedInUserRole: UserRole): boolean {
    return this.userRoleHelper(loggedInUserRole) >= this.userRoleHelper(this.visibleForRole);
  }

  private userRoleHelper(loggedInUserRole: UserRole): number {
    return loggedInUserRole === UserRole.SuperUser ? 1 : loggedInUserRole === UserRole.EditUser ? 0 : -1;
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
