import { AfterViewInit, Directive, Host, Input, OnDestroy, Self } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { pairwise, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[multivalueSearchField]',
})
export class MultivalueSearchFieldDirective implements AfterViewInit, OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();

  @Input()
  multiValueDisabled: boolean;

  constructor(private readonly ngControl: NgControl) {}

  ngAfterViewInit() {
    this.ngControl.valueChanges
      .pipe(pairwise(), takeUntil(this.unsubscriber.done$))
      .subscribe(([previousValue, currentValue]) => {
        if (this.multiValueDisabled && currentValue) {
          const hadComma = previousValue ? previousValue.indexOf(',') > -1 : false;
          const commaIndex = currentValue.indexOf(',');
          if (!hadComma && commaIndex > -1) {
            this.ngControl.control.setValue(currentValue.substring(0, commaIndex).trim());
          }
        }
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }
}
