<div class="back-container">
  <div class="back-container__button" *ngIf="!hideBackBtn">
    <button mat-icon-button (click)="onGoBackClicked()">
      <xpo-icon iconName="arrow-back"></xpo-icon>
    </button>
  </div>
  <div class="back-container__content" [ngClass]="{ hideBackBtn: hideBackBtn }">
    <div class="label">{{ label }}</div>
    <div class="value">{{ value }}</div>
  </div>
  <div class="back-container__actions" *ngIf="actions">
    <button mat-button *ngFor="let action of actions" (click)="action.onClick()">
      <xpo-icon iconName="{{ action.icon }}"></xpo-icon>
      <span *ngIf="action?.text?.includes('Back to')" class="divider">&nbsp;</span>
      <span>{{ action.text }}</span>
    </button>
  </div>
</div>
