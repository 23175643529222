/**
 * Logged in User Role
 *  - Update roles based on your app's needs!
 */
export enum UserRole {
  User = 'User',
  ReadOnlyUser = 'Read Only User',
  EditUser = 'Edit User',
  SuperUser = 'Super User',
}
