import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-shell-footer',
  templateUrl: './app-shell-footer.component.html',
  styleUrls: ['./app-shell-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellFooterComponent {
  @Input() build: string;

  constructor() {}
}
