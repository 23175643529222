import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DropDownService {
  private clearSearchFormSubject = new Subject();
  readonly clearSearchForm$ = this.clearSearchFormSubject.asObservable();

  clearDropDownSelection() {
    this.clearSearchFormSubject.next();
  }

  constructor() {}
}
