<div class="side-nav-bar" [ngClass]="{ collapsed: !!isCollapsed || !!isSmallWindow }" *ngIf="tabs && tabs.length > 0">
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let tab of tabs"
      [matTooltip]="isSmallWindow || isCollapsed ? tab.label : ''"
      matTooltipPosition="right"
      (click)="onClickTab(tab)"
      [active]="activeTab === tab"
    >
      <span class="icon"> <xpo-icon [iconName]="tab.iconName"></xpo-icon> </span>
      <span class="label" *ngIf="!isCollapsed">{{ tab.label }}</span>
    </a>

    <a
      class="toggle-collapse-exapnd"
      [matTooltip]="isSmallWindow ? 'Expand' : ''"
      mat-tab-link
      *ngIf="!isSmallWindow"
      (click)="toggleCollapse()"
    >
      <span class="icon" *ngIf="!isCollapsed"> <xpo-icon iconName="chevron-left"></xpo-icon> </span>
      <span class="icon" *ngIf="!!isCollapsed"> <xpo-icon iconName="chevron-right"></xpo-icon> </span>
      <span class="label" *ngIf="!isCollapsed">Collapse</span>
    </a>
  </nav>
</div>
