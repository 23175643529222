import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[decimal]',
})
export class DecimalDirective {
  @Input() maxDecimals: number = 2;
  @Input() maxIntegers: number = 0; // 0 = limitless

  private regex = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);

  private specialKeys = ['Backspace', 'Tab', 'Del', 'Delete', 'End', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
      return;
    }

    if (next && next.lastIndexOf('.') > -1) {
      const intPart = next.substring(0, next.lastIndexOf('.') - 1);
      if (intPart.length > this.maxIntegers) {
        event.preventDefault();
        return;
      }
      const decimalPart = next.substring(next.lastIndexOf('.') + 1, next.length);
      if (decimalPart.length > this.maxDecimals) {
        event.preventDefault();
        return;
      }
    } else {
      const intPart = next.substring(0, next.length);
      if (intPart.length > this.maxIntegers) {
        event.preventDefault();
        return;
      }
    }
  }
  @HostListener('blur')
  onblur(): void {
    const current: string = this.el.nativeElement.value;
    if (current.lastIndexOf('.') === current.length - 1) {
      this.ngControl.control.setValue(current.substring(0, current.length - 1));
    }
  }
}
