import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoLtlAuthGuard } from '@xpo-ltl/ngx-auth';
import { XpoLtlEnvironmentAndRoleGuard } from '@xpo-ltl/ngx-ltl';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { AppRoutes } from './shared/enums/app-routes.enum';

const routerDefinitions: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./equipment-licensing/equipment-licensing.module').then((m) => m.EquipmentLicensingModule),
    canActivate: [XpoAuthenticationGuard, XpoLtlAuthGuard, XpoLtlEnvironmentAndRoleGuard],
  },
  {
    path: AppRoutes.DATA_ENTRY_PAGE,
    loadChildren: () => import('./data-entry-page/data-entry-page.module').then((m) => m.DataEntryPageModule),
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
