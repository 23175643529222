import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { toString as _toString } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-simple-select-search-dropdown',
  templateUrl: './simple-select-search-dropdown.component.html',
  styleUrls: ['./simple-select-search-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SimpleSelectSearchDropdownComponent implements OnInit, OnDestroy {
  @Input() fieldLabel: string;
  @Input() fieldControl: FormControl;
  @Input() dropdownList: Observable<any[]>;
  @Input() styleClass: string;
  @Input() isRequired: boolean = false;
  @Input() labelClass: string;
  @Input() showHistoryLink: boolean = false;
  @Input() historyFieldName: string;
  @Output() historyLinkClicked = new EventEmitter();
  @Input() showSelectOption: boolean = true;

  private unsubscriber = new Unsubscriber();

  searchFieldControl = new FormControl('');
  filteredList$: Observable<any[]>;

  @ViewChild('cursorField') cursorField: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.filteredList$ = combineLatest([
      this.dropdownList.pipe(takeUntil(this.unsubscriber.done$)),
      this.searchFieldControl.valueChanges.pipe(
        takeUntil(this.unsubscriber.done$),
        startWith(''),
        map((value) => {
          return _toString(value).toLowerCase();
        })
      ),
    ]).pipe(
      map(([optionList, searchText]) => {
        return optionList.filter((option) => option.toLowerCase().includes(searchText));
      })
    );
  }

  focusCursor() {
    this.cursorField.nativeElement.focus();
  }

  fieldHistoryClicked(event: Event) {
    event.stopPropagation();
    this.historyLinkClicked.emit(this.historyFieldName);
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
