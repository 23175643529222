<mat-form-field floatLabel="always" [class]="styleClass">
  <mat-label>{{ fieldLabel }}</mat-label>
  <mat-select
      [formControl]="fieldControl"
      placeholder="Select"
      [multiple]="true"
      (opened)="focusCursor()"
      (closed)="resetSearch()">
    <mat-select-trigger>
      {{ selectedOptionsList.length ? selectedOptionsList.join(', ') : '' }}
    </mat-select-trigger>
    <mat-option class="search-field-option" [disabled]="true">
      <input [formControl]="searchFieldControl" class="search-field" #cursorField placeholder="Search" />
    </mat-option>
    <mat-option
        *ngFor="let option of filteredOptions$ | async"
        [value]="option"
        (click)="onOptionSelected(option)"
        class="select-options">
      <mat-icon [ngStyle]="{ visibility: isSelected(option) ? 'visible' : 'hidden' }">check_circle</mat-icon>
      <p class="option-text">{{ option }}</p>
    </mat-option>
  </mat-select>
</mat-form-field>
