import { NgModule } from '@angular/core';
import { DecimalDirective } from './decimal.directive';
import { EnforceAutocompleteSelectionDirective } from './enforce-autocomplete-selection.directive';
import { HideElementDirective } from './hide-element.directive';
import { MultivalueSearchFieldDirective } from './search-field.directive';

const directives = [
  EnforceAutocompleteSelectionDirective,
  DecimalDirective,
  MultivalueSearchFieldDirective,
  HideElementDirective,
];

@NgModule({
  declarations: directives,
  exports: directives,
})
export class DirectivesModule {}
