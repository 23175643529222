import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { startsWith as _startsWith, toString as _toString } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-search-dropdown',
  templateUrl: './select-search-dropdown.component.html',
  styleUrls: ['./select-search-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectSearchDropdownComponent implements OnInit, OnDestroy {
  @Input() fieldLabel: string;
  @Input() fieldControl: FormControl;
  @Input() dropdownList: any[];
  @Input() styleClass: string;
  @Input() isRequired: boolean = false;
  @Input() labelClass: string;
  @Input() showHistoryLink: boolean = false;
  @Input() historyFieldName: string;
  @Output() historyLinkClicked = new EventEmitter();
  @Input() selectVal: string;
  @Input() showSelectOption: boolean = true;

  private unsubscriber = new Unsubscriber();

  searchFieldControl = new FormControl('');
  filteredList$: Observable<any[]>;

  @ViewChild('cursorField') cursorField: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.filteredList$ = this.searchFieldControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const filterValue = _toString(value).toLowerCase();
        return this.dropdownList.filter((type: any) => _startsWith(type.code.toLowerCase(), filterValue));
      }),
      takeUntil(this.unsubscriber.done$)
    );
  }

  focusCursor() {
    this.cursorField.nativeElement.focus();
  }

  fieldHistoryClicked(event: Event) {
    event.stopPropagation();
    this.historyLinkClicked.emit(this.historyFieldName);
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
