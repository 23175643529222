<ng-container>
  <div class="error-snackbar-container-white-layout">
    <div class="error-snackbar-container-white-layout__title">
      {{ title }}
    </div>
    <mat-divider class="error-snackbar-container-white-layout__top-divider"></mat-divider>
    <div class="error-snackbar-container-white-layout__content">
      <div class="error-snackbar-container-white-layout__content__inner-content">
        <div class="error-snackbar-container-white-layout__content__inner-content__sub-title">
          {{ mainMessage }}
        </div>
        <ul class="error-snackbar-container-white-layout__content__inner-content__messages">
          <ng-container *ngFor="let moreInfo of genericError?.error?.moreInfo">
            <li class="error-snackbar-container-white-layout__content__inner-content__message" *ngIf="moreInfo.message">
              <span>{{ moreInfo.message }}</span>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <mat-divider class="error-snackbar-container-white-layout__bottom-divider"></mat-divider>
    <div class="error-snackbar-container-white-layout__button">
      <button mat-raised-button (click)="onClose()">OK</button>
    </div>
  </div>
</ng-container>
