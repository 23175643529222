import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogConfig } from './confirm-cancel.interface';

/* Interfaces */

@Component({
  selector: 'app-confirm-cancel',
  templateUrl: 'confirm-cancel.component.html',
  styleUrls: ['confirm-cancel.component.scss'],
})
export class DialogConfirmCancelComponent {
  get dialog(): DialogConfig {
    return this.data;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogConfig,
    public dialogRef: MatDialogRef<DialogConfirmCancelComponent>
  ) {}
}
