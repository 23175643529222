import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { DialogConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';

const dialogs = [
  // YOUR DIALOGS
  DialogConfirmCancelComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoDialogModule,
    MatIconModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
