import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { XpoAngularUtilsModule } from '@xpo-ltl/angular-utils';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoChipsModule } from '@xpo-ltl/ngx-ltl-core/chips';
import { XpoDownloadButtonModule } from '@xpo-ltl/ngx-ltl-core/download-button';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoSelectModule } from '@xpo-ltl/ngx-ltl-core/select';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoTabsModule } from '@xpo-ltl/ngx-ltl-core/tabs';
import { DirectivesModule } from '../directives/directives.module';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { AppShellFooterComponent } from './components/app-shell-footer/app-shell-footer.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { GoBackHeaderComponent } from './components/go-back-header/go-back-header.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MultiSelectSearchDropdownComponent } from './components/multi-select-search-dropdown/multi-select-search-dropdown.component';
import { RouterLinkRendererWithLabelComponent } from './components/router-link-renderer-with-label/router-link-renderer-with-label.component';
import { RouterLinkRendererComponent } from './components/router-link-renderer/router-link-renderer.component';
import { SearchGoBackHeaderComponent } from './components/search-go-back-header/search-go-back-header.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SectionNavBarComponent } from './components/section-nav-bar/section-nav-bar.component';
import { SelectSearchDropdownComponent } from './components/select-search-dropdown/select-search-dropdown.component';
import { SideNavBarComponent } from './components/side-nav-bar/side-nav-bar.component';
import { SimpleMultiSelectSearchDropdownComponent } from './components/simple-multi-select-search-dropdown/simple-multi-select-search-dropdown.component';
import { SimpleSelectSearchDropdownComponent } from './components/simple-select-search-dropdown/simple-select-search-dropdown.component';
import { CheckUnsavedChangesGuard } from './guards/check-unsaved-changes/check-unsaved-changes.guard';
import { LogPipe } from './pipes/log.pipe';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    SectionNavBarComponent,
    RouterLinkRendererComponent,
    RouterLinkRendererWithLabelComponent,
    GoBackHeaderComponent,
    SearchGoBackHeaderComponent,
    GoBackHeaderComponent,
    SearchGoBackHeaderComponent,
    SectionHeaderComponent,
    ErrorDialogComponent,
    LoadingSpinnerComponent,
    AppShellFooterComponent,
    SideNavBarComponent,
    SelectSearchDropdownComponent,
    MultiSelectSearchDropdownComponent,
    SimpleSelectSearchDropdownComponent,
    SimpleMultiSelectSearchDropdownComponent,

    // Pipes/Directives
    LogPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    XpoTabsModule,
    XpoCardModule,
    XpoButtonModule,
    XpoDownloadButtonModule,
    XpoSnackBarModule,
    XpoChipsModule,
    XpoFooterModule,
    XpoIconModule,
    XpoSelectModule,
    DirectivesModule,

    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatSidenavModule,
    MatRadioModule,
    MatDividerModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatAutocompleteModule,
    // Pipes/Directives
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    SectionNavBarComponent,
    RouterLinkRendererComponent,
    GoBackHeaderComponent,
    SearchGoBackHeaderComponent,
    SectionHeaderComponent,
    LoadingSpinnerComponent,
    AppShellFooterComponent,
    SideNavBarComponent,
    SelectSearchDropdownComponent,
    MultiSelectSearchDropdownComponent,
    SimpleSelectSearchDropdownComponent,
    SimpleMultiSelectSearchDropdownComponent,

    // Pipes/Directives
    LogPipe,

    // Modules
    CommonModule,
    XpoTabsModule,
    XpoCardModule,
    XpoButtonModule,
    XpoDownloadButtonModule,
    XpoSnackBarModule,
    XpoChipsModule,
    XpoIconModule,

    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatSidenavModule,
  ],
  entryComponents: [RouterLinkRendererComponent, RouterLinkRendererWithLabelComponent],
  providers: [CheckUnsavedChangesGuard],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
