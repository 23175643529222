import { Injectable } from '@angular/core';
import { ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EquipmentRecordsRouteParam } from '../../../equipment-licensing/components/equipment-records/enums/equipment-records-route-param.enum';
import { EquipmentRecordsRoute } from '../../../equipment-licensing/components/equipment-records/enums/equipment-records-route.enum';
import { FleetCodeRouteParam } from '../../../equipment-licensing/components/fleet-codes/enums/fleet-code-route-param.enum';
import { FleetCodesRoute } from '../../../equipment-licensing/components/fleet-codes/enums/fleet-codes-route.enum';
import { LicenseCodesRoute } from '../../../equipment-licensing/components/license-codes/enums/license-codes-route.enum';
import { LicenseRouteParam } from '../../../equipment-licensing/components/license-codes/enums/license-records-route-param.enum';
import { ReqirementsRouteParam } from '../../../equipment-licensing/components/requirements/enums/requirements-route-param.enum';
import { RequirementsRoute } from '../../../equipment-licensing/components/requirements/enums/requirements-route.enum';
import { AppRoutes } from '../../enums/app-routes.enum';

export interface MainNavigation {
  label: string;
  path: AppRoutes;
}

export interface SectionTab {
  label: string;
  path: SectionRoute;
  param?: SectionRouteParam;
  iconName?: string;
}

export type SectionRoute = EquipmentRecordsRoute | LicenseCodesRoute | RequirementsRoute | FleetCodesRoute;

export type SectionRouteParam =
  | EquipmentRecordsRouteParam
  | LicenseRouteParam
  | ReqirementsRouteParam
  | FleetCodeRouteParam;

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  get activeSection(): MainNavigation {
    return this.activeSectionSubject.value;
  }
  set activeSection(value: MainNavigation) {
    this.activeSectionSubject.next(value);
  }

  constructor(private router: Router) {
    this.currentUrlSubject.next(this.router.url);

    // Check navbar collapsing
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this.checkCollapseNavbar(event.url);
    });

    // Refresh active tabs on navigation change
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentUrlSubject.next(event.urlAfterRedirects);
      this.refreshActiveSection(event.urlAfterRedirects);
      this.currentParamsMapSubject.next(this.currentParams);
    });

    // Refresh current params on navigation change
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.currentParams = {};
    });
    this.router.events.pipe(filter((event) => event instanceof ActivationEnd)).subscribe((event: ActivationEnd) => {
      this.currentParams = {
        ...this.currentParams,
        ...event.snapshot.params,
      };
    });
  }
  sections: MainNavigation[] = [
    {
      label: 'Equipment',
      path: AppRoutes.EQUIPMENT,
    },
    {
      label: 'License Code',
      path: AppRoutes.LICENSE_CODE,
    },
    {
      label: 'Requirement',
      path: AppRoutes.REQUIREMENT,
    },
    {
      label: 'Fleet Code',
      path: AppRoutes.FLEET_CODE,
    },
  ];

  loadingSpinnerSubject = new BehaviorSubject<boolean>(false);

  private activeSectionSubject = new BehaviorSubject<MainNavigation>(undefined);
  activeSection$ = this.activeSectionSubject.asObservable();

  private collapseNavbarSubject = new Subject();
  collapseNavbar$ = this.collapseNavbarSubject.asObservable();

  private currentParams = {};
  private currentParamsMapSubject = new BehaviorSubject<{ [key: string]: string }>({});
  currentParamsMap$ = this.currentParamsMapSubject.asObservable();

  private currentUrlSubject = new BehaviorSubject<string>(undefined);
  currentUrl$ = this.currentUrlSubject.asObservable();
  urlCheckpoints: string[] = [];

  private clearHistorySubject = new Subject<boolean>();
  clearHistory$ = this.clearHistorySubject.asObservable();

  private collapseSubject = new BehaviorSubject<boolean>(false);
  collapse$ = this.collapseSubject.asObservable();
  showSpinner() {
    this.loadingSpinnerSubject.next(true);
  }
  hideSpinner() {
    this.loadingSpinnerSubject.next(false);
  }

  refreshActiveSection(url: string) {
    // Detect active section
    const newActiveSection = this.sections.find((section) => url.startsWith(`/${section.path}`));
    if (!!newActiveSection) {
      this.activeSection = newActiveSection;
    }
  }

  /**
   * Check if left navbar should be hidden on navigation.
   * Collapse for main section routes.
   *
   * @param url new route
   */
  checkCollapseNavbar(url: string) {
    if (!this.activeSection) {
      // skip on first page loading
      return;
    }
    if (this.sections.map((s) => '/' + s.path).includes(url)) {
      this.collapseNavbarSubject.next();
    }
  }

  /**
   * Navigate to a new section tab
   *
   * @param tab
   */
  navigateToSectionTab(tab: SectionTab) {
    if (tab.param) {
      this.router.navigate([this.activeSection.path, tab.path, this.currentParams[tab.param]]);
    } else {
      this.router.navigate([this.activeSection.path, tab.path]);
    }
  }

  clearHistory() {
    this.urlCheckpoints.length = 0;
    this.clearHistorySubject.next(true);
  }

  addUrlCheckpoint() {
    if (_.last(this.urlCheckpoints) !== this.currentUrlSubject.value) {
      this.urlCheckpoints.push(this.currentUrlSubject.value);
    }
  }

  goBack() {
    this.urlCheckpoints.pop();
    const lastUrl = _.last(this.urlCheckpoints);
    if (lastUrl) {
      this.router.navigateByUrl(this.urlCheckpoints.pop());
    } else {
      // back to section default
      this.router.navigate([this.activeSection.path]);
    }
  }

  toggleCollapse() {
    this.collapseSubject.next(!this.collapseSubject.getValue());
  }

  hideSidebar() {
    this.collapseNavbarSubject.next(false);
  }
  showSidebar() {
    this.collapseNavbarSubject.next(true);
  }
}
