import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { XpoLtlDialogTitleModule, XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const dialogs = [NotAuthorizedComponent];

@NgModule({
  declarations: [...dialogs],
  exports: [...dialogs],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    DragDropModule,
    FormsModule,
    XpoDialogModule,
    XpoLtlPipeModule,
    XpoLtlDialogTitleModule,
  ],
  entryComponents: [...dialogs],
})
export class AppDialogModule {}
