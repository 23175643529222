<div class="back-container">
  <div class="back-container__button">
    <button mat-icon-button (click)="onGoBackClicked()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
  </div>
  <div class="back-container__content">
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ subtitle }}</div>
  </div>
</div>
