import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { toString as _toString } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { DropDownService } from '../../services/drop-down.service';

@Component({
  selector: 'app-simple-multi-select-search-dropdown',
  templateUrl: './simple-multi-select-search-dropdown.component.html',
  styleUrls: ['./simple-multi-select-search-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SimpleMultiSelectSearchDropdownComponent implements OnInit, OnDestroy {
  @Input() fieldLabel: string;
  @Input() fieldControl: FormControl;
  @Input() dropdownList: Observable<any[]>;
  @Input() styleClass: string;

  selectedOptions: Set<string> = new Set();
  filteredOptions$: Observable<any[]>;
  searchFieldControl = new FormControl('');

  @ViewChild('cursorField') cursorField: ElementRef;

  private unsubscriber = new Unsubscriber();

  constructor(private dropDownService: DropDownService) {}

  ngOnInit(): void {
    this.filteredOptions$ = combineLatest([
      this.dropdownList.pipe(takeUntil(this.unsubscriber.done$)),
      this.searchFieldControl.valueChanges.pipe(
        takeUntil(this.unsubscriber.done$),
        startWith(''),
        map((value) => {
          return _toString(value).toLowerCase();
        })
      ),
    ]).pipe(
      map(([optionList, searchText]) => {
        return optionList.filter((option) => option.toLowerCase().includes(searchText));
      })
    );
    this.dropDownService.clearSearchForm$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.clearAllSelection();
    });
  }

  onOptionSelected(option: any): void {
    if (this.selectedOptions.has(option)) {
      this.selectedOptions.delete(option);
    } else {
      this.selectedOptions.add(option);
    }
  }

  focusCursor() {
    this.cursorField.nativeElement.focus();
  }

  resetSearch() {
    this.searchFieldControl.setValue('');
    this.fieldControl.setValue(this.selectedOptionsList.length ? this.selectedOptionsList : null);
  }

  isSelected(option: any): boolean {
    return this.selectedOptions.has(option);
  }

  get selectedOptionsList(): string[] {
    return Array.from(this.selectedOptions);
  }

  clearAllSelection() {
    this.selectedOptions.clear();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
