import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { AgRendererComponent } from 'ag-grid-angular';
import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const FADE_TIME_MS = 500;
const START_FADE_TIME_MS = 5500;

enum AnimState {
  SHOW = 'show',
  FADE_IN = 'fadeIn',
  FADE_OUT = 'fadeOut',
  NO_SHOW = 'noShow',
}
@Component({
  templateUrl: './router-link-renderer-with-label.component.html',
  styleUrls: ['./router-link-renderer-with-label.component.scss'],
  animations: [
    trigger('currentAnimState', [
      state('void', style({ opacity: 0 })),

      state(AnimState.FADE_IN, style({ opacity: 1 })),
      state(AnimState.SHOW, style({ opacity: 1 })),
      state(AnimState.FADE_OUT, style({ opacity: 0 })),
      state(AnimState.NO_SHOW, style({ opacity: 0 })),

      transition(`* => ${AnimState.NO_SHOW}`, animate('0ms')),
      transition(`* => ${AnimState.SHOW}`, animate('0ms')),
      transition(`* => ${AnimState.FADE_IN}`, animate(FADE_TIME_MS + 'ms')),
      transition(`* => ${AnimState.FADE_OUT}`, animate(FADE_TIME_MS + 'ms')),
    ]),
  ],
})
export class RouterLinkRendererWithLabelComponent implements AgRendererComponent, OnDestroy {
  private unsubscriber = new Unsubscriber();
  currentAnimState = AnimState.NO_SHOW;

  params: {
    value: any;
    inRouterLink: string;
    labelText: string;
    shouldShowLabel: (id) => boolean;
    timeElapsedSinceUpdate: () => number;
  };

  constructor(private ngZone: NgZone, private router: Router, private cdr: ChangeDetectorRef) {}

  agInit(params: any): void {
    this.params = params;
    this.refreshAnimationState();
  }

  refresh(params: any): boolean {
    return false;
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  // This was needed to make the link work correctly
  navigate(link) {
    this.ngZone.run(() => {
      this.router.navigate([link, this.params.value]);
    });
  }

  /**
   * The animation here is a little tricky because the AgGrid virtual scroll
   * dinamically creates/destroy component instances. This renderer component may be created
   * at any point in time, so we must set the initial animation state, and listen for changes
   * if it's necessary.
   */
  private refreshAnimationState() {
    let newState;
    if (this.params.shouldShowLabel(this.params.value)) {
      if (this.params.timeElapsedSinceUpdate() <= FADE_TIME_MS) {
        newState = AnimState.FADE_IN; // t <= 500ms
      } else if (this.params.timeElapsedSinceUpdate() > START_FADE_TIME_MS) {
        if (this.params.timeElapsedSinceUpdate() <= START_FADE_TIME_MS + FADE_TIME_MS) {
          newState = AnimState.FADE_OUT; // 5500ms < t <= 6000ms
        } else {
          newState = AnimState.NO_SHOW; // 6000ms < t
        }
      } else {
        newState = AnimState.SHOW; // 500ms < t <= 5500ms
      }
    } else {
      newState = AnimState.NO_SHOW; // row not updated
    }

    if (newState !== this.currentAnimState) {
      if (newState === AnimState.SHOW || newState === AnimState.FADE_IN) {
        // cancel previous subscription
        this.unsubscriber.complete();
        this.unsubscriber = new Unsubscriber();

        // listen for changes
        interval(100)
          .pipe(takeUntil(this.unsubscriber.done$))
          .subscribe(() => {
            this.refreshAnimationState();
          });
      } else if (newState === AnimState.NO_SHOW) {
        // stop listening
        this.unsubscriber.complete();
      }

      this.currentAnimState = newState;
      this.cdr.detectChanges();
    }
  }
}
