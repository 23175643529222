import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-search-go-back-header',
  templateUrl: './search-go-back-header.component.html',
  styleUrls: ['./search-go-back-header.component.scss'],
})
export class SearchGoBackHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {}

  onGoBackClicked() {
    this.navigationService.goBack();
  }
}
