import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DataApiService } from '@xpo-ltl/data-api';
import { Observable } from 'rxjs';

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {
  private dataApi: DataApiService = undefined;
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.dataApi) {
      this.dataApi = this.injector.get<DataApiService>(DataApiService);
    }
    if (this.dataApi) {
      if (req.method !== 'GET') {
        this.dataApi.startNewCorrelationSession();
      }

      return next.handle(req.clone({ headers: req.headers.set('x-correlation-id', this.dataApi.getCorrelationId()) }));
    }
    return next.handle(req);
  }
}
