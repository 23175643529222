import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import {
  XpoBrowserGuardModule,
  XpoLtlDateSelectorModule,
  XpoLtlDmsUtilsModule,
  XpoLtlDocTypePipe,
  XpoLtlEnvironmentAndRoleGuardModule,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherModule,
} from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverModule } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoCommonModule } from '@xpo-ltl/ngx-ltl-core/common';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { XpoFeedbackModule } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoMessagingPopoverModule } from '@xpo-ltl/ngx-ltl-core/messaging-popover';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { EquipmentLicensingApiService } from '@xpo-ltl/sdk-equipmentlicensing';
import { HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { AngularSplitModule } from 'angular-split';
import { CoreModule } from '../core';
import { NotAuthorizedComponent } from '../core/dialogs';
import { AppDialogModule } from '../core/dialogs/app-dialog.module';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogModule } from './dialogs/dialog.module';
import { CorrelationIdInterceptor } from './shared/interceptors/correlation-id.interceptor';
import { SharedModule } from './shared/shared.module';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

const materialModules = [
  MatButtonModule,
  MatDatepickerModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatTabsModule,
  MatSelectModule,
];

const xpoLtlComponentModules = [
  XpoCommonModule,
  XpoDialogModule,
  XpoLtlDateSelectorModule,
  XpoSnackBarModule,
  XpoLtlDmsUtilsModule,
  XpoLtlEnvironmentAndRoleGuardModule,
  XpoBrowserGuardModule,
  XpoFeedbackModule,
  XpoAccountPopoverModule,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherModule,
  XpoMessagingPopoverModule,
  XpoIconModule,
  XpoShellModule,
];

const apiServices = [EquipmentLicensingApiService, HumanResourceApiService];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ...materialModules,
    ...xpoLtlComponentModules,
    CoreModule,
    DialogModule,
    SharedModule,
    AppDialogModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkLoggingModule,
    AngularSplitModule.forRoot(),
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    // XpoMaintenanceModule.initialize(<MaintenanceConfig>{ appName: SupportedAppEnum.PND }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
  ],
  entryComponents: [NotAuthorizedComponent],
  providers: [
    ...apiServices,
    { provide: HTTP_INTERCEPTORS, useClass: CorrelationIdInterceptor, multi: true },
    XpoLtlDocTypePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
