<div class="confirm-cancel-dialog">
  <div class="confirm-cancel-dialog-title" mat-dialog-title>
    <mat-icon class="confirm-cancel-dialog-title-icon">{{ dialog.icon }}</mat-icon>
    <h1 class="confirm-cancel-dialog-title-text">{{ dialog.title }}</h1>
  </div>
  <h3
    class="confirm-cancel-dialog-content"
    [ngClass]="{ 'confirm-cancel-dialog-content--with-icon': dialog.icon }"
    mat-dialog-content
    [innerHTML]="dialog.content"
  ></h3>
  <div class="confirm-cancel-dialog-actions" mat-dialog-actions>
    <ng-container *ngFor="let action of dialog.actions; let i = index">
      <button
        *ngIf="action.type === 'primary'"
        mat-flat-button
        [ngClass]="{ 'confirm-cancel-dialog--btn-ml-1': i !== 0 }"
        (click)="dialogRef.close({ resultType: action.resultType, resultAction: action.resultAction })"
      >
        {{ action.label }}
      </button>
      <button
        *ngIf="action.type === 'secondary'"
        mat-stroked-button
        [ngClass]="{ 'confirm-cancel-dialog--btn-ml-1': i !== 0 }"
        (click)="dialogRef.close({ resultType: action.resultType, resultAction: action.resultAction })"
      >
        {{ action.label }}
      </button>
    </ng-container>
  </div>
</div>
