<mat-form-field floatLabel="always" [class]="styleClass">
  <mat-label [class]="labelClass" (click)="fieldHistoryClicked($event)" [ngClass]="{ 'blue-label': showHistoryLink }">{{
    fieldLabel
  }}</mat-label>
  <mat-select
      placeholder="Select"
      xpoSelect
      [formControl]="fieldControl"
      (opened)="focusCursor()"
      [required]="isRequired">
    <mat-option class="search-field-option" [disabled]="true">
      <input [formControl]="searchFieldControl" class="search-field" #cursorField placeholder="Search" />
    </mat-option>
    <ng-container *ngIf="!isRequired && showSelectOption">
      <mat-option [value]="selectVal">Select</mat-option>
    </ng-container>
    <ng-container *ngFor="let option of filteredList$ | async">
      <mat-option [value]="option.code">{{ option.code }}</mat-option>
    </ng-container>
  </mat-select>
  <mat-error *ngIf="isRequired && fieldControl.touched && fieldControl.invalid">
    <div *ngIf="fieldControl.errors.required">This field can't be empty</div>
  </mat-error>
</mat-form-field>
