export enum AppRoutes {
  DATA_ENTRY_PAGE = 'data-entry-page',
  NOT_AUTHORIZED_PAGE = 'not-authorized',

  EQUIPMENT = 'equipment',
  LICENSE_CODE = 'license-code',
  REQUIREMENT = 'requirement',
  FLEET_CODE = 'fleet-code',
  REPORTING = 'reporting',
}
