import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { DropDownService } from 'app/shared/services/drop-down.service';
import { startsWith as _startsWith, toString as _toString } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

export interface MatSelectSearchDropdownItem {
  code: string;
  name: string;
}

@Component({
  selector: 'app-multi-select-search-dropdown',
  templateUrl: './multi-select-search-dropdown.component.html',
  styleUrls: ['./multi-select-search-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectSearchDropdownComponent implements OnInit, OnDestroy {
  @Input() fieldLabel: string;
  @Input() fieldControl: FormControl;
  @Input() dropdownList: MatSelectSearchDropdownItem[];
  @Input() styleClass: string;

  selectedOptions: Set<string> = new Set();
  filteredOptions$: Observable<MatSelectSearchDropdownItem[]>;
  searchFieldControl = new FormControl('');

  @ViewChild('cursorField') cursorField: ElementRef;

  private unsubscriber = new Unsubscriber();

  constructor(private dropDownService: DropDownService) {}

  ngOnInit(): void {
    this.filteredOptions$ = this.searchFieldControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const filterValue = _toString(value).toLowerCase();
        return this.dropdownList.filter((type: any) => _startsWith(type.code.toLowerCase(), filterValue));
      }),
      takeUntil(this.unsubscriber.done$)
    );

    this.dropDownService.clearSearchForm$.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      this.clearAllSelection();
    });
  }

  onOptionSelected(option: any): void {
    if (this.selectedOptions.has(option.code)) {
      this.selectedOptions.delete(option.code);
    } else {
      this.selectedOptions.add(option.code);
    }
  }

  focusCursor() {
    this.cursorField.nativeElement.focus();
  }

  resetSearch() {
    this.searchFieldControl.setValue('');
    this.fieldControl.setValue(this.selectedOptionsList.length ? this.selectedOptionsList : null);
  }

  isSelected(option: any): boolean {
    return this.selectedOptions.has(option.code);
  }

  get selectedOptionsList(): string[] {
    return Array.from(this.selectedOptions);
  }

  clearAllSelection() {
    this.selectedOptions.clear();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }
}
