import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogAction } from '../../../dialogs/confirm-cancel/confirm-cancel.interface';
import { LicensingDialogService } from '../../../dialogs/licensing-dialog/licensing.dialog.service';

@Injectable()
export class CheckUnsavedChangesGuard implements CanDeactivate<any> {
  constructor(private dialogService: LicensingDialogService) {}

  canDeactivate(component): Observable<boolean> | Promise<boolean> | boolean {
    if (!component.canExit()) {
      return this.dialogService.showCheckUnsavedDialog().pipe(
        map((res: DialogAction) => {
          return res ? res.resultAction : false;
        })
      );
    } else {
      return true;
    }
  }
}
